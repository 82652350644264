import React from "react";
import { ApolloClient, ApolloProvider, from } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { cache } from "./cache";
import typeDefs from "./typeDefs";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const uploadLink = createUploadLink({
  uri: "/graphql-api/",
  headers: {
    "keep-alive": "true",
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const message =
    typeof graphQLErrors === "object" && graphQLErrors?.[0]?.message;

    console.log(graphQLErrors, networkError);

  if (
    message === "token is expired" ||
    message === "Unable to parse authentication" ||
    message === "Error decoding token headers." ||
    message === "Wrong token found, try again later."
  ) {
    window.localStorage.clear();
    window.location.href = "/login";
  }
});

const authLink = setContext((_, { headers }) => {
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");

  return {
    headers: {
      ...headers,
      authorization: auth?.access_token ? `JWT ${auth.access_token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(uploadLink)]),
  cache,
  typeDefs,
});

export function GrapQLProvider({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default client;
